@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "inter"; /*Can be any text*/
  src: local("Inter-Regular"),
    url("./fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "inter"; /*Can be any text*/
  src: local("Inter-Black"), url("./fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "inter"; /*Can be any text*/
  src: local("Inter-Medium"), url("./fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "inter"; /*Can be any text*/
  src: local("Inter-Light"), url("./fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "inter"; /*Can be any text*/
  src: local("Inter-ExtraLight"),
    url("./fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "inter"; /*Can be any text*/
  src: local("Inter-thin"), url("./fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "inter"; /*Can be any text*/
  src: local("Inter-SemiBold"),
    url("./fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "inter"; /*Can be any text*/
  src: local("Inter-Bold"), url("./fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "inter"; /*Can be any text*/
  src: local("Inter-ExtraBold"),
    url("./fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 800;
}
body {
  margin: 0;
  font-family: "inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
